<script>
import { mapActions } from "vuex";
import Chart from "./Chart";

export default {
	name: "TbaChart",
	extends: Chart,
	computed: {
		chart() {
			return this.$store.getters.chart("tba");
		},
		title() {
			return "To Be Announced Anime";
		}
	},
	beforeMount() {
		this.fetchChart("TBA");
	},
	beforeRouteUpdate(to, from, next) {
		this.fetchChart("TBA");
		next();
	},
	methods: {
		async fetchTBA(year, season) {
			await this.$store.dispatch("fetchTBA");
		}
	},
	metaInfo() {
		const title = `AniChart: TBA Anime Chart`;
		const description = `Find, track, and share future anime on AniChart. Find the top-rated and most popular shows, OVAs, and movies!`;
		return {
			title,
			meta: [
				{
					name: "description",
					content: description
				},
				{
					property: "og:title",
					content: title
				},
				{
					property: "og:description",
					content: description
				},
				{
					property: "og:url",
					content: `https://anichart.net${this.$route.path}`
				},
				{
					property: "twitter:title",
					content: title
				},
				{
					property: "twitter:description",
					content: description
				}
			]
		};
	}
};
</script>
